




import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "./entity/EntityCrud.vue";
import userModel from "@/api/user.model";

@Component({
	components: { EntityCrud },
})
export default class Admins extends Vue {
	model: any = userModel;

	title = "Administradores";
	tableColumns = ["name", "email"];
	filter = { role: "EVALUATOR" };

	formColumns = ["name", "email", "password"];
	defaultEntity = { role: "EVALUATOR", status: "ACTIVE" };
}
